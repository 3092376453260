import { useEffect, useState } from 'react';
import LayOut from '../components/common/Layout';
import MusicCard from '../components/unspokenMusic/MusicCard';
import musicMetadata from '../assets/music/metadata.json';
import Heading from '../components/common/Heading';

interface MusicData {
  id: number;
  filename: string;
  title?: string;
  artist?: string;
}

export default function Music() {
  const [musicData, setMusicData] = useState<MusicData[]>([]);
  const [currentPlayingId, setCurrentPlayingId] = useState<number | null>(null); // Track currently playing song

  useEffect(() => {
    // Load music metadata
    setMusicData(musicMetadata);
  }, []);

  const handlePlay = (id: number) => {
    setCurrentPlayingId(id);
  };

  return (
    <LayOut layoutStyle="bg-white">
      {/* Header Section */}
      <div className="text-center pt-20 pb-5">
        <div className="inset-0 bg-gradient-to-b from-transparent via-gray-800 to-gray-900 opacity-30 rounded-lg"></div>
        <Heading text="Unspoken Music" />
        <p className="text-gray-500 text-lg mx-auto max-w-3xl tracking-wide">
          Let the music immerse you, guiding you deeper into the unspoken world.
        </p>
      </div>

      {/* Artistic Music Collection Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-4">
        {musicData.map((music) => (
          <div key={music.id}>
            <MusicCard
              musicFileName={music.filename}
              title={music.title || 'Untitled Soundtrack'}
              artist={music.artist || 'Unknown Artist'}
              isPlaying={currentPlayingId === music.id}
              onPlay={() => handlePlay(music.id)}
              onStop={() => setCurrentPlayingId(null)}
            />
          </div>
        ))}
      </div>
    </LayOut>
  );
}
