import { useState, useRef, useEffect } from 'react';

interface MusicCardProps {
  musicFileName: string;
  title?: string;
  artist?: string;
  isPlaying?: boolean;
  onPlay?: () => void;
  onStop?: () => void;
}

export default function MusicCard({
  musicFileName,
  title = 'Untitled Soundtrack',
  artist = 'Unknown Artist',
  isPlaying = false,
  onPlay,
  onStop,
}: MusicCardProps) {
  const [isPlayingLocal, setIsPlayingLocal] = useState(isPlaying); // Local state for single song
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isLooping, setIsLooping] = useState(false);

  // Sync local state with prop for multi-song scenario
  useEffect(() => {
    setIsPlayingLocal(isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      if (isPlayingLocal) {
        audioElement.play().catch(() => {
          // handle error
        });
      } else {
        audioElement.pause();
      }
    }
  }, [isPlayingLocal]);

  const togglePlay = () => {
    if (onPlay && onStop) {
      // Use the parent functions when provided (multi-music scenario)
      if (isPlaying) {
        onStop(); // Stop the current song via parent control
      } else {
        onPlay(); // Start the new song via parent control
      }
    } else {
      // Local state management (single music scenario)
      setIsPlayingLocal(!isPlayingLocal);
    }
  };

  const handleEnded = () => {
    if (onStop) {
      onStop(); // Notify parent that the song has ended
    } else {
      setIsPlayingLocal(false); // Default stop behavior for single music
    }
  };

  const toggleLoop = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.loop = !audioElement.loop;
      setIsLooping(audioElement.loop);
    }
  };

  const musicPath = `/assets/music/${musicFileName}`;

  return (
    <div className="p-4 text-left">
      <div className="border border-gray-300 rounded-xl shadow-lg p-6 bg-gradient-to-r from-gray-800 to-gray-900">
        <div className="flex gap-4 items-center">
          {/* Sound Bars */}
          <div className="flex mb-5">
            <div
              className={`flex space-x-1 ${
                isPlayingLocal ? 'animate-bars' : ''
              }`}
            >
              {[...Array(5)].map((_, i) => (
                <div
                  key={i}
                  className="w-2 h-10 bg-gray-300 rounded animate-pulse"
                  style={{
                    animationDuration: `${0.4 + i * 0.1}s`,
                    animationPlayState: isPlayingLocal ? 'running' : 'paused',
                  }}
                ></div>
              ))}
            </div>
          </div>

          <div>
            <p className="text-gray-300 italic">
              {title} <span className="text-gray-400"> by </span> {artist}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-2 mt-4">
          {/* Play/Pause Button */}
          <button
            onClick={togglePlay}
            className="bg-white text-gray-800 py-2 px-6 rounded-full hover:bg-gray-700 hover:text-white transition duration-500 shadow-lg"
          >
            {isPlayingLocal ? (
              <span className="flex">
                <span className="mr-2">Pause</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <rect x="6" y="4" width="4" height="16"></rect>
                  <rect x="14" y="4" width="4" height="16"></rect>
                </svg>
              </span>
            ) : (
              <span className="flex">
                <span className="mr-2">Play</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <polygon points="5 3 19 12 5 21 5 3"></polygon>
                </svg>
              </span>
            )}
          </button>
          {/* Loop Icon */}
          <div
            onClick={toggleLoop}
            className={`cursor-pointer ${
              isLooping ? 'text-custom-sand' : 'text-gray-500'
            }`}
            title="Toggle Loop"
          >
            <svg
              className="w-8 h-8"
              viewBox="0 0 30 30"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                d="M23.5 16C22 20 18 23 14 23C8.5 23 4 19 3.3 13.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 10C5.5 6 9 2.5 14 2.5C19.5 2.5 24 7 24.5 12"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.5 23L24.5 17.5C24.5 17.2 24.2 17 23.9 17H17"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10.5H4C3.7 10.5 3.5 10.2 3.5 9.5V4"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        {/* Audio element */}
        <audio ref={audioRef} src={musicPath} onEnded={handleEnded} hidden />
      </div>
    </div>
  );
}
